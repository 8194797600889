import React from 'react'
import { LiaSchoolSolid } from 'react-icons/lia'
import { IoSchoolOutline } from 'react-icons/io5'
import { MdOutlineWorkHistory } from 'react-icons/md'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import './Education.scss'

function Education() {
    return (
        <div className='education'>
            <VerticalTimeline
                layout='1-column-left'
                lineColor='linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 71%, rgba(255,255,255,0.5) 72%, rgba(255,255,255,0.5) 100%)'
            >
                <VerticalTimelineElement
                    contentStyle={{ background: '#4133ea', color: '#ffffff', boxShadow: 'unset' }}
                    contentArrowStyle={{ borderRight: '7px solid  #4133ea' }}
                    iconStyle={{ background: '#4133ea', color: '#ffffff' }}
                    date="2018-2021"
                    icon={<LiaSchoolSolid />}
                >
                    <h3 className="vertical-timeline-element-title">Vlietland College</h3>
                    <h4 className="vertical-timeline-element-subtitle">Havo</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{ background: '#4133ea', color: '#ffffff', boxShadow: 'unset' }}
                    contentArrowStyle={{ borderRight: '7px solid  #4133ea' }}
                    iconStyle={{ background: '#4133ea', color: '#ffffff' }}
                    date="2021-2024"
                    icon={<IoSchoolOutline />}
                >
                    <h3 className="vertical-timeline-element-title">MboRijnland</h3>
                    <h4 className="vertical-timeline-element-subtitle">Software Developer</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{ background: '#4133ea', color: '#ffffff', boxShadow: 'unset' }}
                    contentArrowStyle={{ borderRight: '7px solid  #4133ea' }}
                    iconStyle={{ background: '#4133ea', color: '#ffffff' }}
                    date="2022-2023"
                    icon={<MdOutlineWorkHistory />}
                >
                    <h3 className="vertical-timeline-element-title">Appstudio</h3>
                    <h4 className="vertical-timeline-element-subtitle">Internship</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{ background: '#4133ea', color: 'rgba(255,255,255,0.5)', boxShadow: 'unset' }}
                    contentArrowStyle={{ borderRight: '7px solid  #4133ea' }}
                    iconStyle={{ background: '#4133ea', color: 'rgba(255,255,255,0.5)', boxShadow: 'unset' }}
                    date="2024-2028"
                    icon={<IoSchoolOutline />}
                >
                    <h3 className="vertical-timeline-element-title">Hogeschool van Amsterdam</h3>
                    <h4 className="vertical-timeline-element-subtitle">ICT - Software Engeenering</h4>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}

export default Education
