import './Projects.scss'
import { LiaGithub } from 'react-icons/lia'

function Projects() {
    return (
        <div className='projects'>
            <p>More Coming Soon...</p>
            <a href="https://github.com/TinusNL/" target='_blank' rel="noreferrer noopener"><LiaGithub /> Github</a>
        </div>
    )
}

export default Projects
