import './Header.scss'
import { LiaDiscord, LiaInstagram, LiaGithub, LiaLinkedin } from 'react-icons/lia'

function Header() {
    return (
        <header className='Header'>
            <div className='nav'>
                <a href="#about">About</a>
                <a href="#education">Education</a>
                <a href="#projects">Projects</a>
            </div>
            <div className='socials'>
                <a href='https://discord.com/users/358676702496555008/' target='_blank' rel="noreferrer noopener" className='discord'>
                    <LiaDiscord />
                </a>
                <a href='https://www.instagram.com/martijn.de.roode/' target='_blank' rel="noreferrer noopener" className='instagram'>
                    <LiaInstagram />
                </a>
                <a href='https://github.com/TinusNL/' target='_blank' rel="noreferrer noopener" className='github'>
                    <LiaGithub />
                </a>
                <a href='https://www.linkedin.com/in/martijn-de-roode/' target='_blank' rel="noreferrer noopener" className='github'>
                    <LiaLinkedin />
                </a>
            </div>
        </header>
    )
}

export default Header
