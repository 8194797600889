import React from 'react'
import { ReactElement } from 'react'
import './Selector.scss'

function Selector({ hash, contents }: { hash: string, contents: { [key: string]: ReactElement } }) {
    hash = hash.replace('#', '')
    hash = contents[hash] ? hash : Object.keys(contents)[0]

    React.useEffect(() => {
        const selectorContent = document.getElementById('SelectorContent')
        const activeContent = document.querySelector('#SelectorContent > div.active')

        selectorContent!.style.height = activeContent!.clientHeight + 'px'
        selectorContent?.scrollIntoView({ behavior: 'smooth' })
    }, [hash])

    return (
        <>
            <div className='Selector'>
                {Object.keys(contents).map((key) => (
                    <a className={hash === key ? 'active' : ''} href={`#${key}`}>{key}</a>
                ))}
            </div>
            <div id='SelectorContent'>
                {Object.keys(contents).map((key) => (
                    <div className={hash === key ? 'active' : ''}>{contents[key]}</div>
                ))}
            </div>
        </>
    )
}

export default Selector
