import React from 'react'
import './About.scss'

function About() {
    return (
        <div className='about'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Vel orci porta non pulvinar neque. Consequat interdum varius sit amet mattis vulputate. Potenti nullam ac tortor vitae purus faucibus ornare. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Iaculis at erat pellentesque adipiscing commodo elit at. Vel eros donec ac odio tempor. Commodo elit at imperdiet dui accumsan sit amet nulla. Sagittis orci a scelerisque purus semper eget. Blandit cursus risus at ultrices mi tempus imperdiet nulla.</p>
            <p>Ultricies leo integer malesuada nunc vel. Consequat semper viverra nam libero justo. Amet risus nullam eget felis. Scelerisque varius morbi enim nunc faucibus a pellentesque sit. Ornare arcu dui vivamus arcu felis bibendum ut. Ornare suspendisse sed nisi lacus sed viverra tellus. Sagittis vitae et leo duis ut diam quam nulla. Arcu risus quis varius quam quisque id diam vel. Nibh tellus molestie nunc non. Faucibus vitae aliquet nec ullamcorper sit amet. Lectus magna fringilla urna porttitor. Massa tincidunt dui ut ornare lectus sit. Curabitur gravida arcu ac tortor.</p>
        </div>
    )
}

export default About
