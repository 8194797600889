import React from 'react'
import Header from '../components/Header'
import Selector from '../components/Selector'

import About from '../components/Content/About'
import Education from '../components/Content/Education'
import Projects from '../components/Content/Projects'

import Astronaut from '../assets/Astronaut.png'
import './Home.scss'


function Home() {
    const [hash, setHash] = React.useState(window.location.hash)

    React.useEffect(() => {
        window.addEventListener('hashchange', () => {
            setHash(window.location.hash)
        })
    }, [])

    return (
        <div className="Home">
            <Header />
            <div className='banner'>
                <div className='name'>
                    <h1 className='firstname'>Martijn</h1>
                    <h1 className='lastname'>de Roode</h1>
                </div>
                <div className="image">
                    <img src={Astronaut} alt="Astronaut" />
                </div>
            </div>
            <h2 className='title'>Web Developer</h2>
            <div className='info'>
                <Selector
                    hash={hash}
                    contents={{
                        'about': <About />,
                        'education': <Education />,
                        'projects': <Projects />
                    }}
                />
            </div>
        </div >
    )
}

export default Home
